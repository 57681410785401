<template>
  <div class="follow-policy">
    <div class="follow"><span>关注政策</span><span v-if="flag" @click="clickPolicySet">关注设置&gt;</span></div>
    <div class="content" v-if="hasBindEntity">
      <div v-if="flag">
        <!-- 数据加载框 -->
        <div class="loading"
        v-show="loading"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.7)">
        </div>
        <div class="zc-content" v-for="(item, i) in list" :key="i" v-show="!loading && list.length > 0">
          <div v-show="!item.readState" class="unread"></div>
          <div class="title" @click="goToZCDetail(item.id, item.sysFollowPolicyId)">
            <div>
              <div>{{ item.title }}</div>
              <div><span>{{ item.fwDepartment }}</span><span>{{ item.dispatchDate }}</span></div>
            </div>
            <div>&gt;</div>
          </div>
          <ul v-if="item.formalFile.length !== 0">
            <li v-for="(item2, i2) in item.formalFile" :key="i2" @click="goToXMDetail(item.id, item2.id, item.sysFollowPolicyId)">
              <div>{{ item2.subtitle }}</div>
              <div>
                <span 
                  v-for="(item3, i3) in item2.fuchifangshi" 
                  :key="i3" 
                  :class="{
                    'fundSupport':item3==='资金扶持',
                    'equityInvestment':item3==='股权投资',
                    'titleRecognition':item3==='称号认定',
                    'taxAndFeeReductions':item3==='减税降费',
                    'financialSupport':item3==='金融扶持',
                    'others':item3==='其他',
                    }">
                    {{ item3 }}
                  </span>
                </div>
              <div v-if="item2.declareDate === -1">已截止</div>
              <div v-else-if="item2.declareDate === 0" class="red">今天截止</div>
              <div v-else :class="{'red':item2.declareDate<=30}">剩 {{ item2.declareDate }} 天</div>
            </li>
          </ul>
        </div>
        <div class="none-data" v-show="!loading && list.length <= 0">
          <el-empty description="暂无内容"></el-empty>
        </div>
        <el-pagination
          v-show="list.length > 0"
          background
          layout="prev, pager, next"
          :total="total"
          @prev-click="prevClick"
          @next-click="nextClick"
          @current-change="currentChange">
        </el-pagination>
      </div>
      <div v-else class="no-content">
        <el-result subTitle="请先添加您要关注的政策">
          <template slot="icon">
            <img src="../../../assets/pc/images/push/noContent.png" alt="">
          </template>
          <template slot="extra">
            <el-button plain size="medium" @click="followPolicySet = true">去添加</el-button>
          </template>
        </el-result>
      </div>
    </div>
    <div v-else style="margin-bottom: 50px">
      <div class="hasBindEntity">
        <div>
          <img src="../../../assets/pc/images/push/subject.png" alt="" />
        </div>
        <div style="margin-top: 10px" class="information_color">
          请先绑定主体
        </div>
        <div style="margin: 10px 0" class="information_color">
          以便查询更多政策资讯
        </div>
        <div class="button" style="text-align: center; margin-top: 30px">
          <button class="goto-bind" @click="dialogVisible = true">
            立即绑定
          </button>
        </div>
      </div>
    </div>
    <el-dialog class="follow-policy-dialog" title="关注政策设置" :visible.sync="followPolicySet" width="480px">
      <div class="addKeyword">
        <p>添加关键词</p>
        <div>
          <div style="padding: 5px 0;">
            <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" effect="plain" @close="handleClose(tag)">{{ tag }}</el-tag>
            <el-input 
              placeholder="最长10个字符" 
              :maxlength="10"
              class="input-new-tag" 
              v-if="inputVisible" 
              v-model="inputValue" 
              ref="saveTagInput" 
              size="mini" 
              @keyup.enter.native="handleInputConfirm" 
              @blur="handleInputConfirm">
            </el-input>
          </div>
          <img style="cursor: pointer;" @click="showInput" src="../../../assets/pc/images/push/addTag.png" alt="">
        </div>
      </div>
      <!-- <div>
        <p>主管部门</p>
        <el-select v-model="value" multiple  clearable  placeholder="请选择" @change="valueChange">
          <el-option
            v-for="item in options"
            :key="item"
            :label="item"
            :value="item"
            >
          </el-option>
        </el-select>
      </div> -->
      <div>
        <p>政策级别</p>
        <el-select v-model="value1" multiple clearable  placeholder="请选择" @change="value1Change">
          <el-option
            v-for="item in options1"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div>
        <p>行业分类</p>
        <el-select v-model="value3" multiple clearable  placeholder="请选择" @change="value3Change">
          <el-option
            v-for="item in options3"
            :key="item.uuid"
            :label="item.name"
            :value="item.uuid">
          </el-option>
        </el-select>
      </div> -->
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button type="primary" @click="saveSetting" :loading="btnLoading">保存设置</el-button>
      </div>
    </el-dialog>
     <el-dialog
      title="绑定主体"
      :visible.sync="dialogVisible"
      width="500px"
    >
      <el-form ref="form" :model="form" class="form" :rules="rule" label-position="top">
        <el-form-item prop="name" label="主体名称">
          <el-autocomplete
                class="inline-input"
                v-model="form.name"
                :fetch-suggestions="querySearch"
                placeholder="请输入机构执照名称"
                :trigger-on-focus="false"
                @select="handleSelect"
                :debounce="0"
                style="width:100%"
                clearable
              >
            </el-autocomplete>
        </el-form-item>
        <el-form-item :label="form.label" v-if="form.type == '0'">
          <div class="creditCode">
            <div style="flex:1">{{form.code}}</div>
            <div class="div-code">
              <el-input v-model="form.first_code" maxlength="1" ref="inputRef1"></el-input>
            </div>
            <div class="div-code">
              <el-input v-model="form.second_code"  maxlength="1" ref="inputRef2"></el-input>
            </div>
            <div class="div-code">
              <el-input v-model="form.third_code"  maxlength="1" ref="inputRef3"></el-input>
            </div>
            <div class="div-code">
              <el-input v-model="form.fourth_code"  maxlength="1" ref="inputRef4"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="form.label" v-else>
          <el-input v-model="form.codeInput" placeholder="输入18位统一社会信用代码" maxlength="18"></el-input>
        </el-form-item>
      </el-form>
      <div class="bind-btn-box">
        <button class="bind-btn" style="width:280px" @click="bindEntity">立即绑定</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { request } from '../../../network';
import { setInfoData, getMemberId } from '@/assets/public/utils/token';
import { mapState } from 'vuex';

export default {
  data () {
    return {
      loading:false,
      flag: true,
      followPolicySet: false,
      total: 0,
      list: [],
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      options: [],
      getSettings: {},
      value: '',
      options1: [
        { id: '1', value: '中央' },
        { id: '2', value: '省级' },
        { id: '3', value: '市级' },
        { id: '4', value: '区级' },
        { id: '5', value: '镇街级' }
      ],
      value1: [],
      options3: [
        {
          id: 0,
          name: '文化、体育和娱乐业',
          uuid: 'ea84aa9050965bf0'
        }
      ],
      value3: '',
      pageNo: 1,
      pageSize: 9,
      hasBindEntity: true,
      dialogVisible: false,
      form: {
        name: '',
        code: '',
        first_code: '',
        second_code: '',
        third_code: '',
        fourth_code: '',
        label: '统一社会信用代码',
        type: '1',
        codeInput: '',
        creditCode: '',
        area: '',
        estiblishTime: '',
        regCapital: '',
        legalPersonName: ''
      },
      rule: {
        name: [
          { required: true, message: '请输入机构执照全称', trigger: 'blur' }
        ]
      },
      restaurants: [],
      btnLoading: false,
      isFirst: true
    };
  },
  created () {
    // this.getFocusPolicy()
  },
  mounted () {
    this.getAccountInformation();
  },
  computed: {
    ...mapState({
      selectCity: (state) => state.menu.selectCity
    })
  },
  watch: {
    selectCity: {
      handler (val) {
        this.getFocusPolicy(val);
      },
      immediate: true
    },
    'form.name' (val) {
      if (!val) {
        this.form.code = '';
        this.form.type = '1';
        this.form.label = '统一社会信用代码';
      }
    },
    'form.first_code' (val) {
      if (val !== '') {
        this.$refs[`inputRef${2}`].focus();
      }
    },
    'form.second_code' (val) {
      if (val !== '') {
        this.$refs[`inputRef${3}`].focus();
      } else {
        this.$refs[`inputRef${1}`].focus();
      }
    },
    'form.third_code' (val) {
      if (val !== '') {
        this.$refs[`inputRef${4}`].focus();
      } else {
        this.$refs[`inputRef${2}`].focus();
      }
    },
    'form.fourth_code' (val) {
      if (val === '') {
        this.$refs[`inputRef${3}`].focus();
      }
    }
  },
  // inject:["reload"],
  methods: {
    clickPolicySet () {
      // this.getCompetentDepartment()
      this.getIndustryUuid().then(() => {
        this.getFocusPolicySetting();
      });
      this.followPolicySet = true;
    },
    async getAccountInformation () {
      if (getMemberId() !== '') {
        const { data: res } = await request({
          method: 'GET',
          url: '/pcp/PersonalCenter/accountInformation',
          params: {
            id: getMemberId()
          }
        });
        if (res.code !== 200) return this.$message.error('信息获取失败');
        this.hasBindEntity = !!res.data[0].name;
        return Promise.resolve();
      }
    },
    async getFocusPolicy (city) {
      this.loading = true;
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/intelligentPush/getFocusByPolicy',
        params: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          memberId: getMemberId(),
          city: city
        }
      });
      if (!this.isFirst) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
      this.isFirst = false;
      if (res.code !== 200) return this.$message.error('数据获取失败');
      if (res.code === 200) {
        this.list = res.data.policyList;
        this.total = res.data.totalSize;
      }
      this.loading = false;
      // this.reload();
    },
    handleClose (tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    showInput () {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm () {
      const inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    async getCompetentDepartment () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/intelligentPush/getCompetentDepartment',
        data: {
          id: getMemberId()
        }
      });
      if (res.code !== 200) return this.$message.error('主管部门信息获取失败');
      this.options = res.data;
    },
    async getIndustryUuid () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/intelligentPush/getIndustryUuid',
        data: {
          id: getMemberId()
        }
      });
      if (res.code !== 200) return this.$message.error('行业分类信息获取失败');
      this.options3 = res.data;
      return Promise.resolve();
    },
    async getFocusPolicySetting () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/intelligentPush/getFocusPolicySetting',
        params: {
          id: getMemberId()
        }
      });
      if (res.code !== 200) return this.$message.error('当前关注政策设置信息获取失败');
      this.getSettings = res.data;
      if (!this.getSettings.keyword) {
        this.dynamicTags = [];
      } else {
        this.dynamicTags = this.getSettings.keyword.split(',');
      }
      this.value = this.getSettings.competentDepartment;
      const re = res.data.zcJibie;
      const a = [];
      for (let index = 0; index < re.length; index++) {
        if (re[index] !== ',') {
          a.push(re[index]);
        }
      }
      this.value1 = a;
      if (!this.getSettings.industryUuid) {
        this.value3 = '';
      } else {
        this.value3 = this.getSettings.industryUuid.split(',');
      }
    },
    async saveSetting () {
      this.btnLoading = true;
      let str = '';
      this.value1.forEach((v, i) => {
        if (i === this.value1.length - 1) {
          str += v;
        } else {
          str += v + ',';
        }
      });
      setInfoData(request).then(async res => {
        if (res === 'islogin') {
          const keyword = this.dynamicTags.toString();
          // const industryUuid = this.value3.toString()
          const { data: res } = await request({
            method: 'GET',
            url: '/pcp/intelligentPush/focusPolicySet',
            params: {
              id: getMemberId(),
              keyword,
              // competentDepartment: '全部',
              zcJibie: str
              // industryUuid
            }
          });
          if (res.code !== 200) {
            this.$message.error('保存设置失败');
            this.btnLoading = false;
          } else {
            this.$message.success('保存设置成功');
            this.followPolicySet = false;
            this.getFocusPolicy(localStorage.getItem('selectCity'));
            this.btnLoading = false;
          }
        } else {
          this.btnLoading = false;
          this.$message.error('请先登录再进行操作');
        }
      });
    },
    goToZCDetail (id, sysFollowPolicyId) {
      // this.changeStatus(sysFollowPolicyId);
      this.$router.push({
        path: '/formaldetails',
        query: {
          id
        }
      });
    },
    async changeStatus (id) {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/intelligentPush/updateRead',
        params: { sysFollowPolicyId: id }
      });
      if (res.code === 200 && res.data) this.getFocusPolicy();
    },
    goToXMDetail (id, inputId, sysFollowPolicyId) {
      // this.changeStatus(sysFollowPolicyId);
      this.$router.push({
        path: '/formaldetails/jiedu',
        query: {
          id,
          inputId
        }
      });
    },
    prevClick () {
      this.pageNo--;
      this.getFocusPolicy(localStorage.getItem('selectCity'));
    },
    nextClick () {
      this.pageNo++;
      this.getFocusPolicy(localStorage.getItem('selectCity'));
    },
    currentChange (val) {
      this.pageNo = val;
      this.getFocusPolicy(localStorage.getItem('selectCity'));
    },
    valueChange (val) {
    },
    value1Change (val) {
    },
    value3Change (val) {
    },
    async bindMember (area, estiblishTime, regCapital, legalPersonName, entityName, code) {
      const { data: res } = await request({
        method: 'post',
        url: '/pcp/PersonalCenter/newbindmemberone',
        data: {
          area: area,
          estiblishTime: estiblishTime,
          regCapital: regCapital,
          legalPersonName: legalPersonName,
          memberId: getMemberId(),
          entityName: entityName,
          code: code,
          sysType: 1
        }
      });
      if (res.code !== 200) { return this.$message.error('主体名称或信用代码不正确'); }
      // this.hasBindEntity = true
      this.dialogVisible = !this.dialogVisible;
      this.$message.success('绑定成功');
      const str = JSON.parse(localStorage.getItem('userinfo'));
      str.entityId = res.data.entityId;
      const str2 = JSON.stringify(str);
      localStorage.setItem('userinfo', str2);
      sessionStorage.setItem('userinfo', str2);
      this.getFocusPolicy(localStorage.getItem('selectCity'));
      this.getAccountInformation();
    },
    bindEntity () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.form.type === '0') {
            if (this.form.first_code === this.form.creditCode[14] && this.form.second_code === this.form.creditCode[15] && this.form.third_code === this.form.creditCode[16] && this.form.fourth_code === this.form.creditCode[17]) {
              this.bindMember(this.form.area, this.form.estiblishTime, this.form.regCapital, this.form.legalPersonName, this.form.name, this.form.creditCode);
            } else {
              this.$message.error('请补全后四位');
            }
          } else {
            if (this.form.type === '1' && this.form.codeInput && this.form.codeInput.length === 18) {
              this.bindMember(this.form.area, this.form.estiblishTime, this.form.regCapital, this.form.legalPersonName, this.form.name, this.form.creditCode);
            } else {
              this.$message.error('请输入18位社会信用代码');
            }
          }
        }
      });
    },
    querySearch (queryString, cb) {
      request({
        method: 'GET',
        url: '/pcp/PersonalCenter/getentitynameandcode',
        params: {
          entityName: queryString
        }
      }).then(res => {
        if (res.data.code === 200) {
          res.data.data.items.forEach(v => {
            v.value = v.name;
          });
          let results = [];
          res.data.data.items.map(item => {
            // results.push(Object.assign({},{value:item.name}))
            results.push(item);
          });
          if (results.length === 0) {
            results = [ { value: '暂无数据' } ];
          }
          cb(results);
        }
      }).catch(err => { console.log(err); });
    },
    handleSelect (item) {
      this.form.code = item.creditCode.slice(0, 6) + '******';
      this.form.label = '请补全统一社会信用代码';
      this.form.type = '0';
      this.form.creditCode = item.creditCode;
      this.form.area = item.base;
      this.form.estiblishTime = item.estiblishTime;
      this.form.regCapital = item.regCapital;
      this.form.legalPersonName = item.legalPersonName;
      this.form.name = item.name;
    }
  }
};
</script>

<style lang="less" scoped>
.follow-policy {
  border: 1px solid #B3BBC8;
  border-radius: 5px;
  .follow {
    display: flex;
    height: 55px;
    line-height: 55px;
    background-color: #F1F6FF;
    padding: 0 28px 0 20px;
    border-radius: 5px 5px 0 0;
    span:nth-child(1) {
      flex: 1;
    }
    span:nth-child(2) {
      font-size: 14px;
      color: #156ED0;
      cursor: pointer;
    }
  }
  .content {
    padding: 0 30px 43px 30px;
    box-sizing: border-box;
    .el-pagination {
      text-align: center;
      margin-top: 50px;
    }
    .zc-content {
      border-bottom: 1px solid #B8B8B8;
      padding: 25px 0 25px 10px;
      position: relative;
      .unread{
        width: 8px;
        height: 8px;
        background-color: red;
        border-radius: 50%;
        position: absolute;
        right: 4px;
        top: 10px;
      }
      .title {
        cursor: pointer;
        display: flex;
        &>div:nth-child(1) {
          div:nth-child(2) {
            font-size: 14px;
            margin-top: 15px;
            color: #B8B8B8;
            span {
              margin-right: 25px;
            }
          }
        }
        &>div:nth-child(2) {
          flex: 1;
          text-align: end;
          align-self: center;
          font-size: 18px;
          color: #B8B8B8;
        }
      }
      ul {
        li {
          cursor: pointer;
          display: flex;
          margin-top: 10px;
          height: 50px;
          line-height: 50px;
          border-radius: 5px;
          border: 1px solid #B8B8B8;
          padding: 0 20px;
          box-sizing: border-box;
          div:nth-child(1) {
            font-size: 15px;
            margin-right: 15px;
            opacity: .8;
          }
          div:nth-child(2) {
            span {
              height: 24px;
              line-height: 24px;
              padding: 0 6px;
              font-size: 14px;
              border-radius: 3px;
              margin-right: 10px;
              border: 1px solid #E5E5E5;
              background-color: #fff;
              color: #8D8D8D;
              &.fundSupport {
                border: none;
                background-color: #F5831D;
                color: #fff;
              }
              &.titleRecognition {
                border: none;
                background-color: #11CC88;
                color: #fff;
              }
              &.equityInvestment {
                border: none;
                background-color: #11CC88;
                color: #fff;
              }
              &.taxAndFeeReductions {
                border: none;
                background-color: #E91F0A;
                color: #fff;
              }
              &.financialSupport {
                border: none;
                background-color: #6CC2ED;
                color: #fff;
              }
              &.others {
                border: none;
                background-color: rgba(0, 0, 0, 0.4);
                color: #fff;
              }
            }
          }
          div:nth-child(3) {
            flex: 1;
            text-align: end;
            font-size: 14px;
            color: #8D8D8D;
            &.red {
              color: red;
            }
          }
        }
      }
    }
    .no-content {
      height: 550px;
      position: relative;
      .el-result {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .el-button {
          color: #156ED0;
          border: 1px solid  #156ED0;
        }
      }
    }
  }
  .follow-policy-dialog {
    /deep/.el-dialog__header {
      border-bottom: 1px solid #E5E5E5;
      .el-dialog__title {
        font-size: 16px;
        font-weight: bold;
      }
    }
    /deep/.el-dialog__body {
      padding: 35px 40px;
      font-size: 16px;
      color: #000;
      &>div {
        margin-bottom: 20px;
        &>p {
          margin-bottom: 10px;
        }
        .el-select {
          width: 100%;
        }
        .el-cascader {
          width: 100%;
        }
      }
      .addKeyword {
        &>div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &>div:first-child {
            border: 1px solid #CCCCCC;
            padding: 5px 5px 0 5px;
            width: 370px;
            min-height: 43px;
            line-height: 30px;
            box-sizing: border-box;
            border-radius: 3px;
          }
        }
        .el-tag {
          margin-right: 5px;
          margin-bottom: 5px;
          height: 25px;
          line-height: 25px;
          padding: 0 4px;
          color: #000;
          background-color: #FAFAFA;
          border-color: #D9D9D9;
          .el-tag__close {
            color: #000;
            &:hover {
              background-color: #FAFAFA;
              color: #409eff;
            }
          }
        }
        .input-new-tag {
          width: auto;
        }
      }
    }
  }
}
.none-data {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 340px;
 }
 .hasBindEntity {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.goto-bind {
  margin-right: 10px;
  width: 120px;
  height: 42px;
  border: 1px solid #156ED0;
  border-radius: 5px;
  cursor: pointer;
  background: #fff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #156ED0;
}
.bind-btn-box {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .bind-btn {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    border: none;
    width: 320px;
    height: 46px;
    background: #156ED0;
    border-radius: 5px;
    cursor: pointer;
  }
  /deep/.el-form-item{
    width: 60%;
  }
.form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  /deep/.el-input__inner {
    font-size: 16px;
    // width:150%;
    height: 50px;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }
  .creditCode{
    display: flex;
    .div-code{
        margin-right: 5px;
        text-align:center;
        /deep/.el-input__inner{
          width: 40px;
          height: 30px;
          font-size: 12px;
        }
    }
  }
  .loading{
    height: 300px;
  }
</style>
